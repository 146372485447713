<template>
  <div>
    <nprogress-container />
    <div v-if="isMobile">
      <div class="accessmobile">
        <div>
          <div style="position: absolute; z-index: 0; left: 0; top: 248px">
            <LottieAnimation
              ref="anim"
              :animation-data="require('@/assets/img/lottie/runing.json')"
              :loop="true"
              :autoPlay="true"
            />
          </div>
          <img
            :src="require('@/assets/img/phoneaccess.png')"
            style="position: relative; z-index: 1"
          />
          <div style="margin-top: 60px">Sorry, For Desktop Only</div>
        </div>
      </div>
    </div>
    <div v-else>
      <BaseLayout v-if="isAuth">
        <router-view />
      </BaseLayout>
      <router-view v-else />
    </div>

    <!-- <router-view /> -->
  </div>
</template>

<style src="@/assets/css/style.css" lang="css"></style>
<style src="@/assets/css/responsive.css" lang="css"></style>

<script>
import { isMobile, isAndroid } from "mobile-device-detect";
import { isMobileOnly } from "mobile-device-detect";
import LottieAnimation from "lottie-web-vue";

import BaseLayout from "@/components/Layout/baselayout.vue";
import NprogressContainer from "vue-nprogress/src/NprogressContainer";
import { mapGetters, mapState } from "vuex";

export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "FormLoginRequired" });
  },
  components: {
    NprogressContainer,
    BaseLayout,
    LottieAnimation,
  },
  data() {
    return {
      visible: false,
      screenlock: "",
      elHeight: "",
      elWidth: "",
      isMobileOnly: isMobileOnly,
      isMobile: isMobile,

      //changpswd: localStorage.getItem("data"),
    };
  },

  computed: {
    ...mapState(["token"]),

    ...mapGetters(["isAuth"]),

    userID() {
      return this.$store.state.auth.data.user.id;
    },
  },
  mounted() {
    // console.log("ini lo", this.$refs.allHeight);
    // this.doResize();
  },
  methods: {
    // doResize() {
    //   this.elHeight = this.$refs.allHeight.clientHeight;
    //   this.elWidth = this.$refs.allHeight.clientWidth;
    //   let scale = 0;
    //   scale = Math.min(
    //     screen.width / this.elWidth,
    //     screen.height / this.elHeight
    //   );
    //   //console.log("yuk", screen.width + "" + this.elWidth);
    //   this.$refs.allHeight.style.transform = "scale(" + scale + ")";
    // },
  },
};
</script>
